<template>
    <div>
        <v-card
            class="mb-12"
            
            >
            <v-card-text>
                <h1>Прикрепите документы</h1>
            </v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12"                    
                            sm="6"
                            md="6"
                >
                        <v-img :src="userFiles.passportFront" max-height="200" max-width="150"></v-img>
                        <v-file-input
                            label="Паспорт (главная)"
                            prepend-icon="mdi-camera"
                            truncate-length="25"
                            accept="image/*"
                            :rules="filesRules"
                            show-size
                            counter
                            outlined
                            @change="passportFrontChanged"
                        ></v-file-input>
                    </v-col>
                     <v-col cols="12"                    
                            sm="6"
                            md="6">
                        <v-img :src="userFiles.passportBack" max-height="200" max-width="150"></v-img>
                        <v-file-input
                            label="Паспорт (прописка)"
                            prepend-icon="mdi-camera"
                            truncate-length="25"
                            accept="image/*"
                            :rules="filesRules"
                            show-size                            
                            counter
                            outlined
                            @change="passportBackhanged"
                        ></v-file-input>
                    </v-col>
                     <v-col cols="12"                    
                            sm="6"
                            md="6">
                        <v-img :src="userFiles.driveLicenseFront" max-height="200" max-width="150"></v-img>
                        <v-file-input
                            :label="textDriverFront"
                            prepend-icon="mdi-camera"
                            truncate-length="25"
                            accept="image/*"
                            :rules="filesRules"
                            show-size
                            counter
                            outlined
                            @change="driveLicenseFrontChanged"
                        ></v-file-input>
                    </v-col>
                     <v-col cols="12"                    
                            sm="6"
                            md="6">
                        <v-img :src="userFiles.driveLicenseBack" max-height="200" max-width="150"></v-img>
                        <v-file-input
                            :label="textDriverBack"
                            prepend-icon="mdi-camera"
                            truncate-length="25"
                            accept="image/*"
                            :rules="filesRules"
                            show-size
                            counter
                            outlined
                            @change="driveLicenseBackhanged"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-btn @click="uploadFiles" color="primary" >Сохранить</v-btn>
            </v-container>
        </v-card>
    </div>
</template>

<script>
// :disabled="!allFiles"
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    export default {
        data() {
            return {
                filesRules: [
                    value => !value || value.size < 18 *1024*1024 || 'Файл слишком большой! Максимум 18мб'
                ],
                textDriverFront: window.innerWidth < 400 ? 'Вод.уд.(лицевая)' : 'Водительское (лицевая сторона)',
                textDriverBack: window.innerWidth < 400 ? 'Вод.уд.(обратная)' : 'Водительское (обратная сторона)'
            }
        },
        methods: {
            ...mapActions(['uploadDocFiles']),
            ...mapMutations([''])
            
        },
        computed: {
            allFiles() {
                return this.files.passportFront && this.files.passportBack && this.files.driveLicenseFront && this.files.driveLicenseBack
            },
            
            ...mapGetters(['userFiles']),
            
        }
    }
</script>

<style lang="scss" scoped>
$input-font-size: 30px;
</style>